import React from 'react';
import classnames from 'classnames';
import { useQuery } from 'utils/react-query';
import queries from 'containers/Globals/queries';

import Button from 'components/uiLibrary/Button';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';

import { TP } from 'constants/index';
import { ADVANCED_SEARCH_TABS_NAMES, ADVANCED_SEARCH_TABS_NAMES_LABELS } from 'constants/search';
import { useTranslation } from 'src/i18n';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import LinkButton from 'components/uiLibrary/LinkButton';
import classes from './SearchContentWrapper.module.scss';

const SUBTITLES = {
  [ADVANCED_SEARCH_TABS_NAMES.PERFORMANCES]: {
    text: 'PRODUCTIONS_AND_PERFORMANCES_SUBTITLE',
    params: { performancesCount: '50000+' },
  },
  [ADVANCED_SEARCH_TABS_NAMES.ARTISTS]: {
    text: 'FN_ARTIST_SEARCH_SUBTITLE',
  },
  [ADVANCED_SEARCH_TABS_NAMES.OPERA_ONLINE]: {
    text: 'PRODUCTIONS_AND_PERFORMANCES_VIDEOS_SUBTITLE',
    params: { performancesCount: '650,000+' },
  },
  [ADVANCED_SEARCH_TABS_NAMES.COMPANIES]: {
    text: 'FN_FINDCOMPANIESBYCOUNTRY',
  },
  [ADVANCED_SEARCH_TABS_NAMES.FESTIVAL]: {
    text: 'FN_FINDFESTIVALSBYCOUNTRY',
  },
  [ADVANCED_SEARCH_TABS_NAMES.MANAGER]: {
    text: 'FN_FINDMANAGERSANDAGENCIESBYCOUNTRY',
  },
  [ADVANCED_SEARCH_TABS_NAMES.COMPOSERS]: {
    text: 'FIND_COMPOSERS_BY_NAME',
  },
  [ADVANCED_SEARCH_TABS_NAMES.COMPETITIONS]: {
    text: 'FIND_COMPETITIONS_SUBTITLE',
  },
  [ADVANCED_SEARCH_TABS_NAMES.VENUES]: {
    text: 'FIND_VENUES_BY_COUNTRY',
  },
};

const EntitiesTotalCount = ({ t, festivals, companies, artists, managers, performances }) => (
  <div className={classes.stats}>
    <div className={classes.stats__section}>
      {festivals?.total > 0 && (
        <Typography size="12" weight="medium">
          {festivals?.total}
          <Typography size="12" color="secondary" className={classes.stats__section_title}>
            {t(ADVANCED_SEARCH_TABS_NAMES_LABELS[ADVANCED_SEARCH_TABS_NAMES.FESTIVAL])}
          </Typography>
        </Typography>
      )}
      {companies?.total > 0 && (
        <Typography size="12" weight="medium">
          {companies?.total}
          <Typography size="12" color="secondary" className={classes.stats__section_title}>
            {t(ADVANCED_SEARCH_TABS_NAMES_LABELS[ADVANCED_SEARCH_TABS_NAMES.COMPANIES])}
          </Typography>
        </Typography>
      )}
      {artists?.total > 0 && (
        <Typography size="12" weight="medium">
          {artists?.total}
          <Typography size="12" color="secondary" className={classes.stats__section_title}>
            {t(ADVANCED_SEARCH_TABS_NAMES_LABELS[ADVANCED_SEARCH_TABS_NAMES.ARTISTS])}
          </Typography>
        </Typography>
      )}
    </div>
    <div className={classes.stats__section}>
      {managers?.total > 0 && (
        <Typography size="12" weight="medium">
          {managers?.total}
          <Typography size="12" color="secondary" className={classes.stats__section_title}>
            {t(ADVANCED_SEARCH_TABS_NAMES_LABELS[ADVANCED_SEARCH_TABS_NAMES.MANAGER])}
          </Typography>
        </Typography>
      )}
      {performances?.total > 0 && (
        <Typography size="12" weight="medium">
          {performances?.total}
          <Typography size="12" color="secondary" className={classes.stats__section_title}>
            {t(ADVANCED_SEARCH_TABS_NAMES_LABELS[ADVANCED_SEARCH_TABS_NAMES.PERFORMANCES])}
          </Typography>
        </Typography>
      )}
    </div>
  </div>
);

const SearchContentWrapper = ({
  children,
  activeTab,
  onSearch,
  onReset,
  disabled = false,
  linkProps,
  isClearText = false,
  styles = { actions: '', wrapper: '', searchBtn: '', clearBtn: '' },
  showConcludingBlock = true,
  trackingData = {},
}) => {
  const { t } = useTranslation('NS_DISPLAY_V4');
  const { isMobile } = useDeviceTypeLayouts();
  const { data, isLoading } = useQuery(queries.getHomeNumbers());
  return (
    <div className={classnames(classes.wrapper, { [styles.wrapper]: !!styles.wrapper })}>
      <div className={classes.content}>
        {isMobile && SUBTITLES?.[activeTab]?.text && (
          <Typography variant="p" color="secondary" className={classes.wrapper__subtitle}>
            {t(`${TP}.${SUBTITLES[activeTab].text}`, SUBTITLES[activeTab].params || {})}
          </Typography>
        )}
        {children}
        {isMobile && showConcludingBlock && (
          <div className={classes.concluding}>
            <LinkButton variant="tertiary" {...linkProps} styles={{ root: classes.discoverEntity }} isLink>
              {t(`${TP}.FN_DISCOVER_ALL`, { tab: t(ADVANCED_SEARCH_TABS_NAMES_LABELS[activeTab]) })}
              <SpriteIcon icon="chevron_right" size={14} />
            </LinkButton>
            {!isLoading && data?.data && <EntitiesTotalCount t={t} {...data.data} />}
          </div>
        )}
      </div>
      <div className={classnames(classes.actions, { [styles.actions]: !!styles.actions })}>
        <Button
          variant="tertiary"
          rightIcon={<SpriteIcon icon="restart_alt" />}
          onClick={onReset}
          className={classnames({ [styles.clearBtn]: !!styles.clearBtn })}
          trackingData={{
            ...trackingData,
            subComponent: SUB_COMPONENTS.CLEAR_CTA,
            meta: { ...trackingData.meta, tab: activeTab },
          }}
        >
          {isClearText ? t(`${TP}.FORM_CLEAR_ALL`) : t(`${TP}.RESET`)}
        </Button>
        <Button
          onClick={onSearch}
          disabled={disabled}
          shape="rectangle"
          className={classnames(classes.searchButton, { [styles.searchBtn]: !!styles.searchBtn })}
          trackingData={{
            ...trackingData,
            subComponent: SUB_COMPONENTS.SEARCH_CTA,
            meta: { ...trackingData.meta, tab: activeTab },
          }}
        >
          {t(`${TP}.SEARCH`)}
        </Button>
      </div>
    </div>
  );
};

export default SearchContentWrapper;
